<template>
	<v-row>
		<v-col cols="12">
			<v-card>
				<v-card-text>
					<v-row>
						<v-col>
							<v-text-field
								dense
								outlined
								label="Buscar tabelas por descrição de coluna Sênior"
								append-icon="mdi-magnify"
								class="mb-n7"
								v-model="busca.busca"
								@keydown.enter="listar(0)"
								@click:append="listar(0)"
							></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-progress-linear indeterminate absolute :active="carregando" />
			</v-card>
		</v-col>
		<v-col cols="12">
			<v-card v-if="!dados.lista">
				<v-card-title>Lista de tabelas Sênior</v-card-title>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-divider />
				<v-card-text class="text-body-1 text-center">Sua busca não retornou nenhum registro!</v-card-text>
			</v-card>
			<v-card v-else>
				<v-card-title>Lista de tabelas Sênior</v-card-title>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-expansion-panels flat v-model="panel">
					<v-expansion-panel
						v-for="(registro, i) in dados.lista"
						:key="i"
						active-class="primary white--text"
						class="rounded-0"
					>
						<v-divider />
						<v-expansion-panel-header class="py-2" hide-actions>
							<v-row no-gutters align="center">
								<v-col class="text-body-1 font-weight-bold">
									{{registro.tabela}}
									<br />
									<small class="text-caption">{{registro.descricao}}</small>
								</v-col>
								<v-spacer />
								<v-col class="text-end">
									<v-fade-transition hide-on-leave>
										<v-row no-gutters>
											<v-col class="text-center font-weight-bold text-truncate">
												Total de registros
												<br />
												<v-chip color="primary" small class="mt-1 px-5">{{registro.qtdregistros}}</v-chip>
											</v-col>
										</v-row>
									</v-fade-transition>
								</v-col>
							</v-row>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-simple-table dense class="rounded-0">
								<thead>
									<tr class="blue-grey lighten-4">
										<th>Coluna</th>
										<th>Descrição</th>
										<th>Máscara do campo</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(col, i) in registro.colunas" :key="i">
										<td>{{col.coluna}}</td>
										<td>{{col.descricao}}</td>
										<td v-if="col.mascara">{{col.mascara}}</td>
										<td v-else>-</td>
									</tr>
								</tbody>
							</v-simple-table>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-divider />
				<div class="py-3">
					<Paginacao
						:total="dados.total"
						@atualizar="listar"
						:carregando="carregando"
						:pg="pgSelecionada"
					/>
				</div>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Paginacao from "../Widgets/Paginacao";

export default {
	name: "TiDicionarioSenior",
	components: { Paginacao },
	data: () => ({
		carregando: false,
		panel: "",
		busca: {},
		dados: {},
		pgSelecionada: 0,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
	},
	methods: {
		listar(pg) {
			this.pgSelecionada = pg;
			this.panel = "";
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}ti/dicionario-senior`, {
					busca: this.busca.busca || null,
					limit: this.pgLimit,
					offset: this.pgLimit * pg,
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
	},
	created() {
		this.listar(0);
	},
};
</script>